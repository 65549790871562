import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAssignmentTurnedIn } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О компании и услугах | Freedom Wheels Rentals
			</title>
			<meta name={"description"} content={"Ваша поездка, ваш путь"} />
			<meta property={"og:title"} content={"О компании и услугах | Freedom Wheels Rentals"} />
			<meta property={"og:description"} content={"Ваша поездка, ваш путь"} />
			<meta property={"og:image"} content={"https://zestfulwanderer.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zestfulwanderer.com/img/436257475.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zestfulwanderer.com/img/436257475.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zestfulwanderer.com/img/436257475.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zestfulwanderer.com/img/436257475.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://zestfulwanderer.com/img/436257475.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://zestfulwanderer.com/img/436257475.jpeg"} />
			<meta name={"msapplication-TileColor"} content={"https://zestfulwanderer.com/img/436257475.jpeg"} />
		</Helmet>
		<Components.TukazaeHeader />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),#000000 url(https://zestfulwanderer.com/img/7.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h1"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					О компании
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Откройте для себя наши предложения
				</Text>
				<Text font="--base">
				В компании Freedom Wheels Rentals мы занимаемся не только прокатом мотоциклов. Мы обеспечиваем комплексный прокат, чтобы каждый велосипедист нашел свой идеальный вариант и наслаждался каждым моментом на дороге. Наши услуги рассчитаны как на начинающих гонщиков, так и на опытных энтузиастов, гарантируя, что все ваши потребности будут удовлетворены с профессионализмом и страстью.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 20px 0px"
					color="--dark"
					font="normal 700 48px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Узнайте больше вместе с нами
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Наш разнообразный парк - краеугольный камень наших услуг. В компании Freedom Wheels Rentals мы гордимся тем, что предлагаем широкий выбор мотоциклов, отвечающих любым предпочтениям и уровню мастерства.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Это лишь малая часть того, что предлагает компания Freedom Wheels Rentals. Мы не просто занимаемся мотоциклами - мы занимаемся созданием впечатлений. Если вы ищете быстрый прокат для однодневной поездки или вам нужен надежный партнер для ваших мотоприключений, мы здесь, чтобы сделать это.
					

				</Text>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Why Choose Freedom Wheels Rentals?
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Индивидуальные пакеты аренды: 
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Индивидуальные варианты, соответствующие вашему графику и бюджету.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Экскурсии с гидом: 
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Присоединяйтесь к нашим экскурсиям под руководством экспертов, чтобы получить незабываемые впечатления от катания.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Техническое обслуживание: 
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Высококлассное техническое обслуживание для поддержания арендованного вами велосипеда в идеальном состоянии.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://zestfulwanderer.com/img/8.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					max-height="380px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Вам интересно узнать обо всем, что мы предлагаем? Посетите нас, чтобы ознакомиться с полным спектром наших услуг и узнать, как мы можем сделать вашу следующую поездку незабываемой. Планируете ли вы одиночное приключение или групповую экскурсию, компания Freedom Wheels Rentals станет вашим надежным спутником в дороге.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-secondary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Связаться с нами
				</Link>
			</Box>
		</Section>
		<Components.TukazaeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});